import { ButtonV2 } from "@Modules/common/ButtonV2";
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import VideoPlayer from "repoV2/components/common/VideoPlayer/VideoPlayer";
import { PoweredByExly } from "repoV2/features/CreatorPages/modules/PoweredByExly";
import { getCopyrightString } from "repoV2/utils/common/dataTypes/string";
import styles from "./bottomAboutSection.module.scss";
import commonStyles from "../../root/unfold.module.scss";

export const BottomAboutSection = ({
    preview,
    introVideo,
    hideGetInTouch,
    getInTouchCtaText,
    getInTouchHeaderText,
    onGetInTouchClick,
}: ITemplate.IAboutSectionProps) => {
    return (
        <div className={commonStyles.sectionContainer}>
            {introVideo || preview ? (
                <>
                    <div className={commonStyles.sectionTitle}>Intro Video</div>
                    <div className={styles.videoContainer}>
                        {introVideo ? (
                            <VideoPlayer width="100%" videoUrl={introVideo} />
                        ) : (
                            "< Your Intro Video will be displayed here >"
                        )}
                    </div>
                </>
            ) : null}
            {hideGetInTouch ? null : (
                <>
                    <div className={commonStyles.sectionTitle}>
                        {getInTouchHeaderText}
                    </div>
                    <ButtonV2
                        design="pill"
                        applyTheme
                        className={styles.getInTouchButton}
                        onClick={() => {
                            onGetInTouchClick();
                        }}
                    >
                        {getInTouchCtaText}
                    </ButtonV2>
                </>
            )}
            <hr className={styles.divider} />
            <div className={styles.exlyBrandingContainer}>
                <PoweredByExly
                    exlyTextClass={styles.exlyLogo}
                    exlyClickDisabled={false}
                />
                <br />
                <div>{getCopyrightString()}</div>
            </div>
        </div>
    );
};
