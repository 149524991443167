import { ButtonV2 } from "@Modules/common/ButtonV2";
import { ITemplate } from "@Templates/ITemplate";
import React from "react";
import styles from "./eventListing.module.scss";

export const EventListing = ({
    data,
    onEventListingClick,
}: {
    data: ITemplate.IEventListingData;
    onEventListingClick: ITemplate.IEventSectionsProps["onEventListingClick"];
}) => {
    return (
        <ButtonV2
            design="pill"
            onClick={() => onEventListingClick(data)}
            className={styles.container}
        >
            <div className={styles.title}>{data?.title?.slice(0, 100)}</div>
            <div
                className={styles.shortDescription}
                // eslint-disable-next-line react/no-danger
                // TODO: remove slice from dangerouslySetInnerHTML as its not a good approach to trim HTML string directly use ReadMoreWrapper

                dangerouslySetInnerHTML={{
                    __html: data?.short_description?.slice(0, 200),
                }}
            />
        </ButtonV2>
    );
};
