/* eslint-disable jsx-a11y/anchor-has-content */
import { isEmpty, SECTION_IDS } from "@Utils";
import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { EventListing } from "./EventListing";
import commonStyles from "../../root/unfold.module.scss";

export const EventSection = ({
    sectionTitle,
    eventListings,
    onEventListingClick,
}: ITemplate.IEventSectionsProps) => {
    if (isEmpty(eventListings)) return null;

    return (
        <div className={commonStyles.sectionContainer}>
            <a
                id={SECTION_IDS.eventSections}
                className={commonStyles.sectionAnchor}
            />
            <div className={commonStyles.sectionTitle}>{sectionTitle}</div>
            {eventListings.map(eventListing => (
                <EventListing
                    data={eventListing}
                    key={eventListing.key}
                    onEventListingClick={onEventListingClick}
                />
            ))}
        </div>
    );
};
