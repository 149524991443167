import React from "react";
import { ITemplate } from "@Templates/ITemplate";
import { Navbar } from "@Modules/common";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import styles from "./unfold.module.scss";
import { AboutSection } from "../components/AboutSection";
import { EventSection } from "../components/EventSection";
import { ISectionProps } from "../components/ISectionProps";
import { BottomAboutSection } from "../components/BottomAboutSection";
import { CustomCodeSection } from "../components/CustomCodeSection/CustomCodeSection";

const Unfold = ({
    navbarProps,
    aboutSectionProps,
    eventSectionsProps,
}: ITemplate.IProps): JSX.Element => {
    const commonProps: ISectionProps.ICommonProps = {
        commonStyles: {
            sectionAnchor: styles.sectionAnchor,
            sectionContainer: styles.sectionContainer,
            sectionTitle: styles.sectionTitle,
        },
    };

    /**
     * Why css is kept here?
     * The background property did not work in partially keeping it here and partially in css file.
     */
    const backgroundImage = getOptimisedImageUrl({
        imageUrl: aboutSectionProps.coverImg || "",
        fetchWidth: 2160,
    });
    const backgroundImageType = aboutSectionProps.coverImgType;
    const backgroundFilter =
        "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))";
    let backgroundStyle: Object = {
        background: backgroundFilter,
    };
    if (backgroundImage) {
        if (backgroundImageType === "color") {
            backgroundStyle = { backgroundColor: backgroundImage };
        } else {
            backgroundStyle = {
                backgroundImage: `${backgroundFilter}, url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
            };
        }
    }

    return (
        <div className={styles.root}>
            <Navbar {...navbarProps} />
            <div className={styles.background} style={backgroundStyle}>
                <div className={styles.container}>
                    <AboutSection {...aboutSectionProps} {...commonProps} />
                    <CustomCodeSection
                        position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
                    />
                    <EventSection {...eventSectionsProps} {...commonProps} />
                    <BottomAboutSection
                        {...aboutSectionProps}
                        {...commonProps}
                    />
                    <CustomCodeSection
                        position={CUSTOM_CODES_POSITION.MAIN_CONTENT_END}
                    />
                </div>
            </div>
        </div>
    );
};

export default Unfold;
